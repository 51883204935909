.input-style {
  padding-left: 8px;
  padding-right: 8px;
  border: 1px solid #b9bad2 !important;
  border-radius: 4px;
  height: 48px !important;
  background: #ffffff;
  box-sizing: border-box;
  font-size: 16px;
  color: #02030d;
}

.textarea-style {
  padding-left: 8px;
  padding-right: 8px;
  border: 1px solid #b9bad2 !important;
  border-radius: 4px;
  background: #ffffff;
  box-sizing: border-box;
  --highlight-color: #000000 !important;
  highlight-color: #000000 !important;
  font-size: 16px;
  color: #02030d;
}

.error-field {
  border: 1px solid red !important;
}

.phone-input {
  padding-left: 8px;
  padding-right: 8px;
  border: 1px solid #b9bad2 !important;
  border-radius: 0 4px 4px 0;
  border-left: none !important;
  height: 48px !important;
  background: #ffffff;
  box-sizing: border-box;
  font-size: 16px;
  color: #02030d;
}

.phone-input-prefix {
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 12px !important;
  padding-top: 12px !important;
  border: 1px solid #b9bad2 !important;
  border-radius: 4px 0 0 4px;
  height: 48px !important;
  max-height: 48px !important;
  background: #ffffff;
  box-sizing: border-box;
  font-size: 16px;
  color: #b9bad2;
}
