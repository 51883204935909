.button-primary-style {
  background: #5a31f4 !important;
  border-radius: 4px;
  color: #ffffff !important;
}

.button-secondary-style {
  background: #ffffff !important;
  border-radius: 4px;
  color: #000000 !important;
  border: 1px solid #b9bad2;
}

.disabled-button {
  background: #e5e4e9 !important;
  color: #a1a2ae !important;
}
