.str-chat {
  --str-chat__primary-color: #5a31f4;
  --str-chat__surface-color: #efebfe;
  --str-chat__secondary-surface-color: #ffffff;
  --str-chat__primary-surface-color: #5a31f4;
  --str-chat__primary-surface-color-low-emphasis: #5a31f4;
  --str-chat__border-radius-circle: 6px;
  --str-chat__channel-list-background-color: #f7f7f9;
  --str-chat__channel-preview-background-color: #f7f7f9;
  --str-chat__message-list-background-color: #f7f7f9;
  --str-chat__own-message-bubble-color: #f7f7f9;
  --str-chat__own-message-bubble-own-color: #000000;
  --str-chat__message-mention-color: #12d931;
}
