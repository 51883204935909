@import 'node_modules/ol/ol.css';
@import 'node_modules/stream-chat-angular/src/assets/styles/css/index.css';

@import './design-system/flex';
@import './design-system/position';
@import './design-system/sizing';
@import './design-system/spacing';
@import './design-system/display';
@import './design-system/typography-utils';
@import './design-system/cursor';

@import 'design-system/custom/overwrite-ionic-style';
@import 'design-system/custom/colors';
@import 'design-system/custom/fonts';
@import 'design-system/custom/z-index';
@import 'design-system/custom/buttons';
@import 'design-system/custom/modals';
@import 'design-system/custom/inputs';
@import 'design-system/custom/borders';
@import 'design-system/custom/chat';

@import 'projects/ui-components/src/lib/skeletons/styles';

@import 'global';
