.or-color-primary {
  color: #5a31f4 !important;
  --color: #5a31f4 !important;
}

.or-color-primary-2 {
  color: #6d5bb2 !important;
  --color: #6d5bb2 !important;
}

.or-color-secondary {
  color: #dcdada !important;
  --color: #dcdada !important;
}

.or-color-secondary-2 {
  color: #717492 !important;
  --color: #717492 !important;
}

.or-color-secondary-4 {
  color: #a697df !important;
  --color: #a697df !important;
}

.or-color-secondary-5 {
  color: #b9bad2 !important;
  --color: #b9bad2 !important;
}

.or-color-secondary-6 {
  color: #44465b !important;
  --color: #44465b !important;
}

.or-color-black {
  color: #000000 !important;
  --color: #000000 !important;
}

.or-color-white {
  color: #ffffff !important;
  --color: #ffffff !important;
}

.or-color-red {
  color: #ff1313 !important;
  --color: #ff1313 !important;
}

.or-bg-primary {
  background-color: #5a31f4 !important;
  --background-color: #5a31f4 !important;
}

.or-bg-white {
  background: #ffffff !important;
  --background: #ffffff !important;
}

.or-bg-shade {
  background: #f5f5f7 !important;
  --background: #f5f5f7 !important;
}

.or-bg-shade-2 {
  background: #d4d4d4 !important;
  --background: #d4d4d4 !important;
}

.or-bg-focus {
  background: #efebfe !important;
  --background: #efebfe !important;
}

.or-bg-selected {
  background: #e4ddff !important;
  --background: #e4ddff !important;
}

.or-bg-black-2 {
  background: #02030d !important;
  --background: #02030d !important;
}

.or-bg-red {
  background: #ff1313 !important;
  --background: #ff1313 !important;
}

.or-bg-red-2 {
  background: #F6DEE0 !important;
  --background: #F6DEE0 !important;
}

.or-bg-alertbar {
  background: #dfe0ef !important;
  --background: #dfe0ef !important;
}
