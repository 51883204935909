.global-font {
  font-family: 'DM Sans', sans-serif;
  color: #02030d;
}

.or-note-sm {
  font-size: 12px;
  font-weight: 400;
  color: #02030d;
}

.or-text-sm {
  font-size: 12px;
  font-weight: 500;
  color: #02030d;
}

.or-text-md {
  font-size: 14px;
  font-weight: 500;
  color: #02030d;
}

.or-text-lg {
  font-size: 16px;
  font-weight: 500;
  color: #02030d;
}

.or-text-xl {
  font-size: 18px;
  font-weight: 500;
  color: #02030d;
}

.or-head-xs {
  font-size: 12px;
  font-weight: 700;
  color: #02030d;
}

.or-head-sm {
  font-size: 14px;
  font-weight: 700;
  color: #02030d;
}

.or-head-md {
  font-size: 16px;
  font-weight: 700;
  color: #02030d;
}

.or-head-lg {
  font-size: 18px;
  font-weight: 700;
  color: #02030d;
}

.or-head-xl {
  font-size: 20px;
  font-weight: 700;
  color: #02030d;
}

.or-head-xxl {
  font-size: 26px;
  font-weight: 700;
  color: #02030d;
}

.or-head-30 {
  font-size: 30px;
  font-weight: 700;
  color: #02030d;
}
