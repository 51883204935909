.modal-auto {
  --height: auto;
}

.present-modal {
  --border-radius: 10px 10px 10px 10px;
  --height: auto !important;
  --width: 90% !important;
  --background: #f3f3f6;
  --backdrop-opacity: 80%;
}

.ion-loading-bg-none {
  --background: none;
  --backdrop-opacity: 0;
}
