.global-config {
  -webkit-tap-highlight-color: transparent;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

*::-webkit-scrollbar {
  display: none;
}

.text-no-wrap {
  white-space: nowrap;
}

.text-wrap {
  white-space: normal;
}

.white-space-normal {
  white-space: normal;
}

.header-bg-color {
  --background: #02030d;
  background: #02030d;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-x-auto {
  overflow-x: auto;
}

.auth-header {
  top: 0;
  left: 0;
  width: 100%;
  max-height: 40vh;
  object-fit: cover;
  z-index: -1;
}

.custom-divider {
  height: 1px;
  background-color: #dadada;
  width: 80%;
}

.margin-top--20-px {
  margin-top: -20px;
}

.margin-top--24-px {
  margin-top: -24px;
}

.margin-top--1-px {
  margin-top: -1px;
}

.negative-margin-bottom-1-px {
  margin-bottom: -1px;
}

.pac-container {
  z-index: 9000000;
}

.top-container {
  background: #02030d;
  height: 24px;
  margin-top: -1px;
}

.disabled {
  opacity: 25%;
}

textarea:focus.outline-none,
input:focus.outline-none {
  outline: none;
}

.or-toast-style {
  --background: #1d1f30;
  --border-color: 545566;
  --border-radius: 4px;
  --border-width: 1px;
  --border-style: solid;
  --height: 40px;
}

.chips-margin-bottom--20-px {
  margin-bottom: -20px;
}

.or-truncate-at-line-1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
}

button {
  border: none;
}

.card-box-shadow {
  box-shadow: 0 0 18px rgba(2, 3, 13, 0.05);
}
