.or-text-transform-none {
  text-transform: none;
}

.or-text-transform-lowercase {
  text-transform: lowercase;
}

.or-text-transform-uppercase {
  text-transform: uppercase;
}

.or-text-transform-capitalize {
  text-transform: capitalize;
}

.or-text-transform-capitalize-first-letter:first-letter {
  text-transform: capitalize;
}

.or-font-style-italic {
  font-style: italic;
}

.or-text-decoration-underline {
  text-decoration: underline;
}

.or-text-decoration-unset {
  text-decoration: unset;
}

.or-text-decoration-none {
  text-decoration: none;
}

.or-text-decoration-none-i {
  text-decoration: none !important;
}

.or-text-decoration-line-through {
  text-decoration: line-through;
}
