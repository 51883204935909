.or-cursor-pointer {
  cursor: pointer;
}

.or-cursor-default {
  cursor: default;
}

.or-cursor-text {
  cursor: text;
}

.or-cursor-not-allowed {
  cursor: not-allowed;
}

.or-cursor-help {
  cursor: help;
}

.or-cursor-grab {
  cursor: grab;
}

.or-cursor-grabbing {
  cursor: grabbing;
}

.or-pe-none {
  pointer-events: none;
}

.or-pe-none-i {
  pointer-events: none !important;
}

.or-pe-auto {
  pointer-events: auto;
}

.or-pe-auto-i {
  pointer-events: auto !important;
}
