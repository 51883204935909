ion-range {
  --bar-background-active: #5a31f4;
  --knob-size: 15px;
  --knob-background: #5a31f4;
}

ion-checkbox::part(container) {
  border-radius: 4px;
}

ion-checkbox {
  --checkbox-background-checked: #5a31f4;
  --checkmark-width: 2px;
  --border-color-checked: #5a31f4;
}

ion-checkbox.checkbox-disabled {
  --checkbox-background-checked: #d3d0db;
  --checkmark-width: 2px;
  --border-color-checked: #d3d0db;
}

ion-card {
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
}

ion-tab-bar {
  --background: #ffffff;
}

ion-toast {
  --background: #000000;
  --color: #ffffff;
}

ion-select.custom-select {
  background: #ffffff;
  border: 1px solid #b9bad2;
  border-radius: 4px;
  --background: #ffffff;
  --padding-start: 8px;
  --highlight-color: #000000;
  --height: 44px !important;
  height: 44px !important;
  padding: 1px;
  width: calc(100% - 4px);
  font-size: 16px;
  color: #02030d;
}

ion-chip {
  border-radius: 6px;
  min-height: 24px;
  padding: 6px 8px;
}

ion-spinner {
  width: 50px !important;
  height: 50px !important;
  --spinner-color: #5a31f4;
}

ion-toggle {
  --handle-background-checked: #ffffff;
  --track-background-checked: #5a31f4;
  --handle-background: #ffffff;
  --track-background: #d3d0db;
}
