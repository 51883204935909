$colorOne: #dfe0ef;
$colorTwo: #d2d8d9;

.or-skeleton-animated {
  overflow: hidden;
  background-color: $colorOne;

  &::before {
    display: block;
    content: '';
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to right, $colorOne 0%, $colorTwo 20%, $colorOne 40%, $colorOne 100%);
    background-repeat: no-repeat;
    background-size: var(--skeleton-animation-background-size, 100vw);

    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);

    animation: skeleton-loading 2.5s infinite linear;

    @keyframes skeleton-loading {
      0% {
        background-position: calc(-1 * var(--skeleton-animation-bounds, 10vw));
      }

      100% {
        background-position: var(--skeleton-animation-bounds, 10vw);
      }
    }
  }
}
