.border-radius-4 {
  border-radius: 4px;
}

.border-radius-8 {
  border-radius: 8px;
}

.border-radius-12 {
  border-radius: 12px;
}

.border-radius-20 {
  border-radius: 20px;
}

.border-radius-100-pc {
  border-radius: 100%;
}

.border-shade {
  border: 1px solid #a6a6a6 !important;
}

.border-primary {
  border: 1px solid #5a31f4 !important;
}

.border-black {
  border: 1px solid #000000 !important;
}

.border-red {
  border: 1px solid #FF1313 !important;
}
